// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-clips-js": () => import("./../../../src/pages/clips.js" /* webpackChunkName: "component---src-pages-clips-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-qa-js": () => import("./../../../src/pages/qa.js" /* webpackChunkName: "component---src-pages-qa-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-pages-themes-articles-js": () => import("./../../../src/pages/themes-articles.js" /* webpackChunkName: "component---src-pages-themes-articles-js" */),
  "component---src-pages-themes-js": () => import("./../../../src/pages/themes.js" /* webpackChunkName: "component---src-pages-themes-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-clip-js": () => import("./../../../src/templates/clip.js" /* webpackChunkName: "component---src-templates-clip-js" */),
  "component---src-templates-credit-js": () => import("./../../../src/templates/credit.js" /* webpackChunkName: "component---src-templates-credit-js" */),
  "component---src-templates-episode-js": () => import("./../../../src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-templates-expert-js": () => import("./../../../src/templates/expert.js" /* webpackChunkName: "component---src-templates-expert-js" */),
  "component---src-templates-interview-js": () => import("./../../../src/templates/interview.js" /* webpackChunkName: "component---src-templates-interview-js" */),
  "component---src-templates-lesson-js": () => import("./../../../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-qa-js": () => import("./../../../src/templates/qa.js" /* webpackChunkName: "component---src-templates-qa-js" */),
  "component---src-templates-subtheme-js": () => import("./../../../src/templates/subtheme.js" /* webpackChunkName: "component---src-templates-subtheme-js" */),
  "component---src-templates-theme-js": () => import("./../../../src/templates/theme.js" /* webpackChunkName: "component---src-templates-theme-js" */),
  "component---src-templates-transcript-js": () => import("./../../../src/templates/transcript.js" /* webpackChunkName: "component---src-templates-transcript-js" */)
}

